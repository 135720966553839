import Vue from "vue";
import Router from "vue-router";

const index = () => import("../pages/index.vue"); //首页
const goods = () => import("../pages/goods.vue"); //收货刷卡
const inStorage = () => import("../pages/inStorage.vue"); //入库刷卡
const outStorage = () => import("../pages/ outStorage.vue"); //出库刷卡

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: index,
      meta: {
        title: "首页",
      },
    },
    {
      path: "/goods", //收货刷卡
      name: "goods",
      component: goods,
      meta: {
        keepAlive: true,
        title: "收货刷卡",
      },
    },
    {
      path: "/inStorage", //入库刷卡
      name: "inStorage",
      component: inStorage,
      meta: {
        keepAlive: true,
        title: "入库刷卡",
      },
    },
    {
      path: "/outStorage", //出库刷卡
      name: "outStorage",
      component: outStorage,
      meta: {
        keepAlive: true,
        title: "收货刷卡",
      },
    },
    {
      path: "*",
      redirect: "/",
      meta: {},
    },
  ],
});

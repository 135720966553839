import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入全部样式
import 'vant/lib/index.less';

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title= '91智能机刷卡后台-' + to.meta.title
  }
  next()
})


import { NavBar,Form,Field,Button,Popup,Cell, CellGroup } from "vant";
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Cell);
Vue.use(CellGroup);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
